export {}

export enum ReminderType {
    ONE_TIME,
    CRON
}


export class TelegramRequest {
    constructor(readonly reminderType: ReminderType, readonly date: String | null, readonly cron: String | null, readonly zoneId: String) {
    }
}

class TgService {
    public tg = window.Telegram.WebApp;
    public data: TelegramRequest | null = null

    constructor() {
        this.tg.ready()
        this.tg.MainButton.hide()
        console.log("aaaaaaaaaaaaaa")

        this.tg.MainButton
            .onClick(() => {
                if (this.data != null) {
                    this.send()
                }
            })
    }

    send() {
        let request = JSON.stringify(this.data)
        console.log("Send data ")
        console.log(request)

        this.tg.sendData(request)
        this.tg.close()
    }
}

const telegramService = new TgService()

export default telegramService