import * as React from 'react';
import TextField from '@mui/material/TextField';
import {Box, FormControl, InputLabel, Select, SelectChangeEvent, Stack, Typography} from "@mui/material";
import telegramService, {ReminderType, TelegramRequest} from "./services/TgService";
import moment from "moment-timezone";

const names = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье'
];

const everyTypes = new Map([
    ["month", 'Месяц'],
    ["week", 'Неделю'],
    ["day", 'День'],
    ["hour", 'Час']
])

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface ScheduleData {
    type: string

    weekDays: Array<string> | null
    weekDayTime: string | null

    hourTime: number | null

    dayTime: string | null

    monthDayTime: string | null
    monthDay: number | null
}

const scheduleData: ScheduleData = {
    type: "week",

    weekDays: null,
    weekDayTime: null,

    hourTime: null,

    dayTime: null,

    monthDayTime: null,
    monthDay: 1
}


export default function Schedule() {
    const [everyType, setEveryType] = React.useState("week");

    const handleChangeEveryType = (event: SelectChangeEvent) => {
        setEveryType(event.target.value as string)

        scheduleData.type = event.target.value
        scheduleData.weekDays = null
        scheduleData.weekDayTime = null
        scheduleData.hourTime = null
        scheduleData.dayTime = null
        scheduleData.monthDayTime = null
        scheduleData.monthDay = 1
        showHideButton()
    };

    return (
        <>
            <Typography variant="subtitle1" sx={{m: 2}}>
                Повторять раз в
            </Typography>

            <Stack direction="row">
                <Select
                    sx={{width: "100%"}}
                    native
                    value={everyType}
                    onChange={handleChangeEveryType}>
                    {Array
                        .from(everyTypes)
                        .map(([key, value]) => (
                            <option value={key}>{value}</option>
                        ))
                    }
                </Select>

                {everyType == "day" &&
                    <DayType/>
                }


                {everyType == "hour" &&
                    <HourType/>
                }
            </Stack>


            {everyType == "month" &&
                <MonthType/>
            }
            {everyType == "week" &&
                <WeekType/>
            }

        </>
    );
}
const MonthType = () => {
    const [timeValue, setTimeValue] = React.useState<String | null>(null);
    const [dayValue, setDayValue] = React.useState("1");

    return (
        <Stack direction="row">
            <FormControl sx={{width: "50%"}}>
                <InputLabel>День месяца</InputLabel>
                <Select
                    value={dayValue}
                    native
                    onChange={event => {
                        setDayValue(event.target.value);

                        scheduleData.monthDay = parseInt(event.target.value)
                        showHideButton()
                    }}
                >
                    {
                        Array.from(Array(32).keys()).map(value => <option key={value} value={value}>{value}</option>)
                    }
                </Select>
            </FormControl>
            <Box sx={{m: 2}}>
                В
            </Box>
            <TextField
                id="time"
                label="Время"
                type="time"
                onChange={event => {
                    setTimeValue(event.target.value);

                    scheduleData.monthDayTime = event.target.value
                    showHideButton()
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 60,
                }}
                sx={{width: "50%"}}
            />
        </Stack>)
}

const WeekType = () => {
    const [personName, setPersonName] = React.useState<string[]>([]);
    const [timeValue, setTimeValue] = React.useState<string | null>(null);

    const weekHandleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {options} = event.target;
        const value: string[] = [];
        const keys: string[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
                keys.push((i + 1).toString());
            }
        }
        setPersonName(value);


        scheduleData.weekDays = keys
        showHideButton()
    };


    return (
        <Stack direction="row">
            <FormControl sx={{width: "50%"}}>
                <InputLabel shrink htmlFor="select-multiple-native">
                    Дни недели
                </InputLabel>
                <Select
                    multiple
                    native
                    value={personName}
                    // @ts-ignore Typings are not considering `native`
                    onChange={weekHandleChange}
                    label="Дни недели"
                    inputProps={{
                        id: 'select-multiple-native',
                    }}
                >
                    {names.map((name) => (
                        <option key={name} value={name}>
                            {name}
                        </option>
                    ))}
                </Select>
            </FormControl>

            <Box sx={{m: 2}}>
                В
            </Box>

            <TextField
                id="time"
                label="Время для недели"
                type="time"
                onChange={event => {
                    setTimeValue(event.target.value);

                    scheduleData.weekDayTime = event.target.value
                    showHideButton()
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 60,
                }}
                sx={{width: "50%"}}
            />
        </Stack>)
}

const DayType = () => {
    const [timeValue, setTimeValue] = React.useState<string | null>(null);

    return (
        <>
            <Box sx={{m: 2}}>
                В
            </Box>
            <TextField
                id="time"
                label="Время"
                type="time"
                onChange={event => {
                    setTimeValue(event.target.value);

                    scheduleData.dayTime = event.target.value
                    showHideButton()
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 60,
                }}
                sx={{width: 160}}
            />
        </>)
}

const HourType = () => {
    const [timeValue, setTimeValue] = React.useState<string>("0");

    return (
        <>
            <Box sx={{m: 2}}>
                В
            </Box>
            <FormControl sx={{width: 110}}>
                <InputLabel id="demo-simple-select-helper-label">Минуты</InputLabel>
                <Select
                    id="demo-simple-select-helper"
                    labelId="demo-simple-select-helper-label"
                    value={timeValue}
                    label="hour"
                    native
                    onChange={event => {
                        setTimeValue(event.target.value);

                        scheduleData.hourTime = parseInt(event.target.value)
                        showHideButton()
                    }}
                >
                    {
                        Array.from(Array(60).keys()).map(value => <option value={value}>{value}</option>)
                    }
                </Select>
            </FormControl>
        </>
    )
}

function showHideButton() {
    let status = validate()


    if (status) {
        telegramService.data = new TelegramRequest(
            ReminderType.CRON,
            null,
            getCrone(),
            moment.tz.guess()
        )

        telegramService.tg.MainButton.enable()
        telegramService.tg.MainButton.show()
    } else {
        telegramService.data = null
        telegramService.tg.MainButton.disable()
        telegramService.tg.MainButton.hide()
    }

    console.log("scheduleData :")
    console.log(scheduleData)
    console.log("telegramService.data :")
    console.log(telegramService.data)
    console.log("validation status " + status)
}

function getCrone(): string {
    switch (scheduleData.type) {
        case "month": {
            return monthCron()
        }
        case "week": {
            return weekCron()
        }
        case "day": {
            return dayCron()
        }
        case "hour": {
            return hourCron()
        }
        default:
            throw "Unknown scheduleData.type - " + scheduleData.type
    }

}

function monthCron(): string {
    let minutes = "*"
    let hour = "*"
    let dayOfMonth = "*"

    if (scheduleData.monthDayTime != null) {
        let time = scheduleData.monthDayTime.split(":")

        hour = time[0]
        minutes = time[1]
    }
    if (scheduleData.monthDay != null) {
        dayOfMonth = scheduleData.monthDay.toString()
    }

    return "0 " + minutes + " " + hour + " " + dayOfMonth + " " + "* *"
}

function weekCron(): string {
    let minutes = "0"
    let hour = "*"
    let weekDays = "*"

    if (scheduleData.weekDayTime != null) {
        let time = scheduleData.weekDayTime.split(":")

        hour = time[0]
        minutes = time[1]
    }
    if (scheduleData.weekDays?.length) {
        weekDays = scheduleData.weekDays.join(',')
    }

    return "0 " + minutes + " " + hour + " * * " + weekDays
}

function dayCron(): string {
    let minutes = "0"
    let hour = "*"

    if (scheduleData.dayTime != null) {
        let time = scheduleData.dayTime.split(":")

        hour = time[0]
        minutes = time[1]
    }

    return "0 " + minutes + " " + hour + " * * *"
}

function hourCron(): string {
    let minutes = "0"

    if (scheduleData.hourTime != null) {
        minutes = scheduleData.hourTime.toString()
    }

    return "0 " + minutes + " * * * *"
}

function validate() {
    switch (scheduleData.type) {
        case "month": {
            if (scheduleData.monthDay != null && scheduleData.monthDayTime != null) {
                return true
            }
            break

        }
        case "week": {
            if (scheduleData.weekDays?.length && scheduleData.weekDayTime != null) {
                return true
            }
            break
        }
        case "day": {
            if (scheduleData.dayTime != null) {
                return true
            }
            break
        }
        case "hour": {
            if (scheduleData.hourTime != null) {
                return true
            }
            break
        }
    }

    return false
}