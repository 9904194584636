import * as React from 'react';
import {
    Box, Button, Container, Stack, Typography
} from "@mui/material";
import Schedule from "./schedule";
import {EventRepeat, KeyboardBackspace, ScheduleSend} from "@mui/icons-material";
import Onetime from "./onetime";
import telegramService from "./services/TgService";

export default function BasicDatePicker() {
    const [showMenu, setShowMenu] = React.useState(true);
    const [showMenuBackButton, setShowMenuBackButton] = React.useState(false);
    const [showOneTime, setShowOneTime] = React.useState(false);
    const [showSchedule, setShowSchedule] = React.useState(false);

    const onClickOneTime = () => {
        setShowMenu(false)
        setShowOneTime(true)
        setShowMenuBackButton(true)

        telegramService.tg.MainButton
            .hide()
            .disable()
            .setText("Создать разовое напоминание")
    };

    const onClickScheduled = () => {
        setShowMenu(false);
        setShowSchedule(true)
        setShowMenuBackButton(true)

        telegramService.tg.MainButton
            .hide()
            .disable()
            .setText("Создать постоянное напоминание")
    };

    const onClickBackToMenu = () => {
        setShowMenu(true)
        setShowSchedule(false)
        setShowOneTime(false)
        setShowMenuBackButton(false)

        telegramService.tg.MainButton
            .hide()
            .disable()

        telegramService.data = null

    };

    console.log("demo")

    return (
        <Container maxWidth="xs">
            <Stack sx={{m: 2}} spacing={2}>
                <Typography variant="h3">Just Remind Me 📅</Typography>

                {showMenuBackButton &&
                    <Button size="small" sx={{width: 1 / 4}} variant="outlined" onClick={onClickBackToMenu}
                            startIcon={<KeyboardBackspace/>}>
                        назад
                    </Button>
                }

                {showOneTime && <><Onetime/></>}
                {showSchedule && <><Schedule/></>}
                {showMenu &&
                    <>
                        <Button variant="outlined" onClick={onClickOneTime} startIcon={<ScheduleSend/>}
                                fullWidth>
                            Разовое напоминание
                        </Button>
                        <Button variant="outlined" onClick={onClickScheduled} startIcon={<EventRepeat/>} fullWidth>
                            Повторяющиеся событие
                        </Button>
                    </>
                }
            </Stack>
        </Container>
    );
}