import * as React from 'react';
import TextField from '@mui/material/TextField';
import {Box, Stack, Typography} from "@mui/material";
import telegramService, {ReminderType, TelegramRequest} from "./services/TgService";
import moment from "moment-timezone";

export default function Onetime() {
    const [dateValue, setDateValue] = React.useState<string | null>(null);
    const [timeValue, setTimeValue] = React.useState<string | null>(null);

    console.log("oneTime")

    return (
        <>
            <Typography variant="subtitle1">
                Введите дату и время 🖊️
            </Typography>


            <Stack direction="row">
                <TextField
                    id="date"
                    label="Дата напоминания"
                    type="date"
                    onChange={event => {
                        setDateValue(event.target.value)
                        showHideButton(event.target.value, timeValue)
                    }}
                    sx={{width: 180}}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Box sx={{m: 2}}>
                    В
                </Box>
                <TextField
                    id="time"
                    label="Время напоминания"
                    type="time"
                    onChange={event => {
                        setTimeValue(event.target.value)
                        showHideButton(dateValue, event.target.value)
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 60,
                    }}
                    sx={{width: 160}}
                />
            </Stack>
        </>
    );
}


function showHideButton(date: string | null, time: string | null) {
    if (date == null || time == null) {
        console.log("validation false")
        telegramService.tg.MainButton.disable()
        telegramService.tg.MainButton.hide()
        return
    }

    let timeArray = time.split(":")

    let momentDate = moment(new Date(date))
        .set('hour', parseInt(timeArray[0]))
        .set('minute', parseInt(timeArray[1]))

    if (momentDate.isBefore(moment())) {
        console.log("validation false")
        telegramService.tg.MainButton.disable()
        telegramService.tg.MainButton.hide()
        return
    }

    console.log("validation OK")


    telegramService.tg.MainButton.enable()
    telegramService.tg.MainButton.show()

    telegramService.data = new TelegramRequest(
        ReminderType.ONE_TIME,
        momentDate.format(),
        null,
        moment.tz.guess()
    )
}